<template>
  <div
    v-on-click-outside="() => (showMenu = false)"
    class="flex items-center justify-start space-x-2"
  >
    <div class="text-xs">{{ $t("DEALS.DEAL_NOTES.TYPE_OF_NOTE") }}:</div>
    <lf-pill
      data-cy="note-type-toggle"
      size="sm"
      class="h-[22px] relative shadow-fab"
      :class="disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'"
      color-index="23"
      @click="toggleMenu"
    >
      <div class="space-x-2 flex items-center">
        <div class="w-22 truncate text-left">
          <span class="text-headline text-xs">
            {{ noteTypes[noteType].title }}
          </span>
        </div>
        <icon-base
          :icon="IconArrowUp"
          icon-color="#FF6D04"
          height="10"
          width="10"
          :class="{ 'rotate-180 -translate-y-1': showMenu }"
        />
      </div>
    </lf-pill>
    <div
      v-if="showMenu"
      data-cy="note-type-modal"
      class="absolute z-2 top-[34px] rounded bg-white p-2 space-y-2 w-[280px] shadow-popover note-type-menu"
    >
      <div
        v-for="(type, key) in availableNoteTypes"
        :key="key"
        data-cy="note-type-option"
        class="flex items-center space-x-3 p-2 self-stretch rounded hover:bg-primary-background-active cursor-pointer"
        :class="{ 'bg-primary-background-active': noteType === type.type }"
        @click="selectType(type.type)"
      >
        <div
          class="relative flex justify-center items-center gap-2.5 rounded-full w-10 h-10 bg-flush-orange flex-none"
        >
          <icon-base :icon="IconNotes" class="text-white" />
          <div
            v-if="type.icon"
            class="flex p-1 rounded-full border-2 border-divider w-6 h-6 absolute -right-[9px] -bottom-[9px]"
            :class="type.icon?.class"
          >
            <icon-base :icon="type.icon.component" width="12" height="12" />
          </div>
        </div>
        <div class="flex flex-col items-start flex-grow">
          <span class="font-semibold text-headline">{{ type.title }}</span>
          <span>{{ type.subtitle }}</span>
        </div>
        <icon-base
          v-if="noteType === type.type"
          :icon="IconCheck"
          class="text-primary flex-none"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import type { Note } from "@/models/notes";
import LfPill from "@/components/ui/text/LfPill.vue";
import IconArrowUp from "@/components/icons/IconArrowUp.vue";
import IconNotes from "@/components/icons/IconNotes.vue";
import IconCheck from "@/components/icons/IconCheck.vue";
import IconBusiness from "@/components/icons/IconBusiness.vue";
import IconTelephone from "@/components/icons/IconTelephone.vue";
import { NoteType } from "@/enums/notes";

const emit = defineEmits<{
  "type:update": [NoteType];
}>();

const props = defineProps<{
  noteToEdit?: Note | null;
  noteType: NoteType;
  disabled?: boolean;
}>();

const { t } = useI18n();

const noteTypes = {
  application: {
    title: t("DEALS.NOTES.DEAL_NOTE"),
    icon: undefined,
    subtitle: t("DEALS.NOTES.STRATEGIC_INSIGHTS"),
    type: NoteType.Application
  },
  business: {
    title: t("DEALS.NOTES.BUSINESS_NOTE"),
    icon: {
      component: IconBusiness,
      class: "bg-secondary-purple text-white"
    },
    subtitle: t("DEALS.NOTES.BUSINESS_UPDATES"),
    type: NoteType.Business
  },
  call: {
    title: t("DEALS.NOTES.CALL_NOTE"),
    icon: {
      component: IconTelephone,
      class: "bg-secondary-green text-white"
    },
    subtitle: t("DEALS.NOTES.CALL_SUMMARY"),
    type: NoteType.Call
  }
};

const showMenu = ref(false);

const availableNoteTypes = computed(() => {
  if (props.noteToEdit?.id) {
    return noteTypes;
  }
  // @TODO: Restore call note type for new notes when we have call sessions from BE
  return Object.fromEntries(
    Object.entries(noteTypes).filter(([key]) => key !== "call")
  );
});

const toggleMenu = () => {
  if (props.disabled) {
    return;
  }
  showMenu.value = !showMenu.value;
};

const selectType = (type: NoteType) => {
  emit("type:update", type);
  showMenu.value = false;
};
</script>
